<template>
  <div class="container">
    <img
        class="header-image"
        v-if="!isDark"
        loading="lazy"
        src="@/assets/images/bl_header_light.webp"
        alt="Bible Lesson cover"
    />
    <img class="header-image" v-else loading="lazy"
         alt="Bible Lesson cover in dark mode"
         src="@/assets/images/bl_header_dark.webp"/>
    <div class="content-container" :style="{background: myColors.background}">
      <SubtitleBody
          :title="blContent.name"
          :description="blContent.details.description.description"
      />
      <!-- Subtitle body -->
      <Role :roles="blContent.details.role"/>
      <SubtitleBody
          :subtitle="blContent.details.targetAudience.title"
          :description="blContent.details.targetAudience.description"
      />
      <!-- Subtitle body -->
      <SubtitleList subtitle="Business Goals"
                    :list="blContent.details.businessGoals"/>
      <!-- Subtitle list -->
      <div class="section-break"></div>
      <SubtitleBody
          title="UX process"
          :subtitle="blContent.details.uxProcess.processes[0].title"
          :description="blContent.details.uxProcess.processes[0].description"
      />
      <!-- Subtitle body -->
      <Figure caption="Youth: Elliot" textAlignment="center">
        <div class="fig elliot"></div>
      </Figure>
      <!-- Figure -->
      <SubtitleBody
          :subtitle="blContent.details.uxProcess.processes[1].title"
          :description="blContent.details.uxProcess.processes[1].description"
      />
      <!-- Subtitle body -->
      <BodyBullets
          :body="blContent.details.uxProcess
      .processes[1].listDetails[0].description"
          :list="blContent.details.uxProcess
      .processes[1].listDetails[0].list"
      />
      <!-- Body bullets -->
      <SubtitleBody
          :subtitle="blContent.details.uxProcess.processes[2].title"
          :description="blContent.details.uxProcess.processes[2].description"
      />
      <!-- Subtitle body -->

      <div class="figures">
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig desktop-web"></div>
        </Figure>
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig print"></div>
        </Figure>
      </div>
      <!-- Figures -->
      <!-- <div class="section-break"></div> -->
      <!-- Section break -->
      <SubtitleBody
          :subtitle="blContent.details.uxProcess.processes[3].title"
          :description="blContent.details.uxProcess.processes[3].description"
      />
      <!-- Subtitle body -->
      <div class="figures">
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig bottom-nav"></div>
        </Figure>
        <!-- Figure -->
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig top-nav"></div>
        </Figure>
        <!-- Figure -->
      </div>
      <!-- Figures -->
      <InteractionVideo cover-height="39rem" is-desktop="false"
                        video-width="calc(100vw - 4rem)"
                        caption="Top app bar with menu navigation buttons"
                        :video="mobileScrolling"/>
      <!-- Video -->
      <div class="figures">
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig mobile-app"></div>
        </Figure>
        <!-- Figure -->
        <Figure
            caption="Table of contents page. Each card represents a piece of content from the issue."
            textAlignment="left"
        >
          <div class="fig tablet-app"></div>
        </Figure>
        <!-- Figure -->
      </div>
      <!-- Figures -->
      <SubtitleList
          :subtitle="blContent.details.uxProcess.processes[4].title"
          :list="blContent.details.uxProcess.processes[4].listDetails[0].list"
          :boldFirst="true"
      />
      <!-- Subtitle list -->
      <BodyBullets
          :subtitle="blContent.details.verification.title"
          :body="blContent.details.verification.listDetails[0].description"
          :list="blContent.details.verification.listDetails[0].list"
      />
      <!-- Body bullets -->
    </div>
  </div>
  <!-- Content Container -->
</template>

<script>
import {written} from "@/core/data/written_data.ts";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";
import SubtitleList from "@/components/info/desktop/Subtitle_List.vue";
import BodyBullets from "@/components/info/desktop/Body_Bullets.vue";
import Role from "@/components/info/desktop/Role.vue";
import Figure from "@/components/info/desktop/Figure.vue";
import MobileScrolling from "@/assets/videos/bl_mobile_scrolling.mp4";
import InteractionVideo from "@/components/info/InteractionVideo";
import {colors} from "@/core/colors";
import {store} from "@/store/store";


export default {
  components: {
    InteractionVideo,
    SubtitleBody,
    SubtitleList,
    BodyBullets,
    Role,
    Figure,
  },
  data() {
    return {
      coverBackground: "rgba(83, 82, 80, 0.5)",
      iconOpacity: 0.8,
      coverOpacity: 1,
      playing: false,
      mobileScrolling: MobileScrolling
    };
  },
  watch: {
    playing: function (newState, _) {
      if (newState === true) {
        this.coverOpacity = 0;
      } else {
        this.coverOpacity = 1;
      }
    },
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    blContent: function () {
      return written.projects.bibleLesson;
    },
    containerStyle: function () {
      return window.innerWidth < 480
          ? "player-container-mobile"
          : "player-container";
    },
    coverStyle: function () {
      return window.innerWidth < 480 ? "player-cover-mobile" : "player-cover";
    },
    myColors: function () {
      return colors(this.isDark, store)
    }
  },
  methods: {
    animateActive: function () {
      this.coverBackground = "rgba(3, 29, 96, 0.5)";
      this.iconOpacity = 1;
    },
    animateNormal: function () {
      this.coverBackground = "rgba(83, 82, 80, 0.5)";
      this.iconOpacity = 0.8;
      console.log("done");
    },
    endVideo: function () {
      this.playing = true;
    },
    playVideo: function () {
      this.coverOpacity = 0;
      this.playing = true;
      this.$refs.media.play();
    },
  },
};
</script>

<style scoped>
.content-container {
  top: 15rem !important;
  width: 100vw;
}

.container {
  width: 100vw;
  margin: 3rem 0 100% 0;
  display: flex;
  flex-flow: column;
}

.header-image {
  z-index: 0;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
}

.elliot {
  background: url("../../../assets/images/elliot_persona.webp") no-repeat;
}

.desktop-web {
  background: url("../../../assets/images/desktop_app.webp") no-repeat;
  height: 15rem !important;
}

.print {
  background: url("../../../assets/images/print.webp") no-repeat;
}

.bottom-nav {
  background: url("../../../assets/images/bottom_nav.webp") no-repeat;
  height: 10rem !important;
}

.top-nav {
  background: url("../../../assets/images/app_bar.webp") no-repeat;
  height: 10rem !important;
}

.mobile-app {
  background: url("../../../assets/images/mobile_app.webp") no-repeat;
}

.tablet-app {
  background: url("../../../assets/images/tablet_app.webp") no-repeat;
}

.fig {
  width: calc(100vw - 4rem);
  margin: 0 auto;
  height: 20rem;
  background-size: contain;
  background-position: center;
}

.figures {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  width: 100vw;
  padding: 0 !important;
  gap: 4rem;
}

.container {
  margin: 4rem 0 !important;
}

.figures > div {
  height: inherit;
  margin: 0;
}

.figure {
  width: 100vw;
}

.player-container-mobile {
  display: grid !important;
  place-items: center !important;
  place-content: space-around;
  width: 100vw;
  margin: 4rem 0 8rem 0;
}

.player-cover-mobile {
  width: 15rem !important;
  height: 27rem !important;
  display: grid !important;
  place-items: center !important;
}

.body-bullet-container {
  width: calc(100vw - 4rem);
  margin: 0 0 6rem 2rem !important;
}

.list-container {
  margin: 0 2rem 2rem 2rem;
}
</style>